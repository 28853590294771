/**
 * 1. Vivek Yelethotadahalli Srinivas - 1002064152
 * 2. Pallavi Sunkara - 1002076310
 * 3. Sai Manideep Sanganabhatla - 1002118961
 * 4. Karthik Rama Raju Perearla - 1002086333
 * 5. Harsha Vardhan Nunna - 1002159569
 *
 * @format
 */

.course-card {
	border: 1px solid #ccc;
	padding: 20px;
	margin: 10px;
	width: 300px;
	display: inline-block;
	vertical-align: top;
	box-sizing: border-box;
}

.course-card:hover {
	background-color: white;
}

.admin-cards {
	display: flex;
}

.admin-cards div > div {
	display: flex;
	gap: 15px;
	flex-direction: column;
}

table {
	justify-content: flex-start;
	align-items: flex-start;
	width: 70%;
	border-collapse: collapse;
}

table,
th,
td {
	border: 1px solid #ccc;
	background-color: white;
}

th,
td {
	padding: 10px;
	text-align: left;
}

th {
	background-color: #f2f2f2;
}

.textBox {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #c3e9ce;
	max-width: 100%;
	margin: auto;
	height: 100px;
}

.list-wrapper {
	font-family: Arial, sans-serif;
	background-color: #c3e9ce;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	height: 67vh;
}
