/**
 * 1. Vivek Yelethotadahalli Srinivas - 1002064152
 * 2. Pallavi Sunkara - 1002076310
 * 3. Sai Manideep Sanganabhatla - 1002118961
 * 4. Karthik Rama Raju Perearla - 1002086333
 * 5. Harsha Vardhan Nunna - 1002159569
 *
 * @format
 */
.grade-table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 20px;
}
.grade-table th,
td {
	border: 1px solid #dddddd;
	text-align: left;
	padding: 8px;
}
.course-title {
	font-size: 20px;
	font-weight: bold;
	margin: 0;
	color: black;
}

.course-details {
	margin-bottom: 10px;
	font-size: 18px;
	color: black;
	white-space: nowrap;
}

.course-card-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.course-card {
	flex-basis: 100%; /* Two cards per row with a little spacing */
	background-color: #f9f9f9;
	border: 1px solid #ddd;
	border-radius: 5px;
	margin-bottom: 15px;
	padding: 15px;
	cursor: pointer;
	transition: background-color 0.3s;
	text-decoration: none;
	max-width: 100%;
	width: auto;
}

.course-card:hover {
	background-color: #e0e0e0;
}

.instructor-container {
	/* max-width: 100%; */
	padding: 20px 20px;
	height: 100%;
	background-color: #c3e9ce;
	border-radius: 5px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.card-buttons {
	display: compact;
	justify-content: center; /* Center-align the buttons horizontally */
	align-items: center; /* Center-align the buttons vertically */
	margin-top: 10px;
}

.card-button + .card-button {
	margin-left: 10px; /* Adjust the margin as needed */
}

.card-button {
	padding: 10px 20px;
	background-color: #982652;
	justify-content: space-between;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	text-decoration: none;
}
