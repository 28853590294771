/**
 * 1. Vivek Yelethotadahalli Srinivas - 1002064152
 * 2. Pallavi Sunkara - 1002076310
 * 3. Sai Manideep Sanganabhatla - 1002118961
 * 4. Karthik Rama Raju Perearla - 1002086333
 * 5. Harsha Vardhan Nunna - 1002159569
 *
 * @format
 */

.container-fluid {
	width: 100%;
}
.sectionContainer {
	padding: 20px;
	text-align: center;
	color: black;
	background-color: #c3e9ce;
}

.sectionIntro {
	width: 50%;
	text-align: center;
	align-items: center;
	margin: auto;
}

.row {
	background-color: #c3e9ce;
	display: flex;
	align-items: center;
	justify-content: center;
}

.column {
	float: left;
	width: 20%;
	margin-bottom: 16px;
	padding: 4px 8px;
}

.card {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	margin: 8px;
	background-color: white;
	padding: 12px;
}

.home-container {
	padding: 0 16px;
}

.container::after,
.row::after {
	content: "";
	clear: both;
	display: table;
}

.title {
	color: grey;
}

.button {
	border: none;
	outline: 0;
	display: inline-block;
	padding: 8px;
	color: white;
	background-color: #000;
	text-align: center;
	cursor: pointer;
	width: 100%;
}

.button:hover {
	background-color: #555;
}

.footer {
	background-color: #982652;
	color: #fff;
	padding: 20px;
	flex-grow: 1;
	text-align: center;
	border: 1px solid #000;
}

html {
	box-sizing: border-box;
}

body {
	margin: 0;
}

.main-container {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

.navBar {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	position: sticky;
	top: 0;
	cursor: pointer;
	background-color: #982652;
	background-size: cover;
}
@media (max-width: 768px) {
	.navbar {
		flex-direction: column; /* Stack items vertically on small screens */
	}

	.navbar ul {
		width: 100%; /* Make the ul full-width on small screens */
		justify-content: center; /* Center the items horizontally */
	}

	.navbar li {
		margin: 10px 0; /* Add some vertical spacing between items */
	}
}
.navBarMenu {
	display: flex;
	align-items: center;
	justify-content: center;
	position: sticky;
	top: 0;
	cursor: pointer;
	background-color: #ec9c8c;
	background-size: cover;
}

.navBarSubMenu {
	display: flex;
	align-items: center;
	justify-content: center;
	position: sticky;
	top: 0;
	cursor: pointer;
	background-color: #982652;
	background-size: cover;
}

.navList {
	width: 70%;
	display: flex;
}

.navList li {
	list-style: none;
	padding: 6px 40px;
	text-decoration: none;
	color: black;
	font-weight: bold;
}
/* 
.navList li a {
	text-decoration: none;
	color: black;
	font-weight: bold;
	padding: 2px 2px;
} */

.navList li:hover {
	color: grey;
}

.loginButton {
	/* color: #c3e9ce; */
	color: white;
}

.loginButton a {
	text-decoration: none;
	/* color: #c3e9ce; */
	color: white;
}

.loginButton a:hover {
	color: grey;
}

.contactUsMainSection {
	height: 85vh;
	background-color: #c3e9ce;
}

table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 20px;
}

th,
td {
	border: 1px solid #dddddd;
	text-align: left;
	padding: 8px;
}

th {
	background-color: #f2f2f2;
}

td a {
	text-decoration: none;
	margin-right: 10px;
	color: #007bff;
}

.textBox {
	display: flex;
	justify-content: center;
	align-items: center;
	color: black;
	max-width: 50%;
	margin: auto;
	height: 20%;
}

.text-heading {
	font-weight: bold;
	font-size: 44px;
	text-align: center;
}

.contactUsForm {
	border-radius: 5px;
	width: 60%;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
}

input[type="text"] {
	width: 100%;
	padding: 12px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 6px;
	margin-bottom: 16px;
	resize: vertical;
}

input[type="submit"] {
	background-color: #04aa6d;
	color: white;
	padding: 12px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.photo-grid-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	padding: 5px;
	background-color: #c3e9ce;
}

.photo-grid {
	justify-content: center;
	align-items: center;
	display: grid;
	padding: 20px;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
}

.photo-grid-item {
	position: relative;
	overflow: hidden;
}

.photo-grid-item img {
	width: 100%;
	height: auto;
	display: block;
}

.photo-grid-item:hover img {
	transform: scale(1.1);
	transition: transform 0.3s ease;
}

.logo img {
	width: 150px;
	height: auto;
}
.body-for-footer {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	margin: 0;
	background-color: #c3e9ce;
}
.active-page {
	color: white;
	text-decoration: underline;
}

.row-container {
	height: 67vh;
	background-color: #c3e9ce;
}

.row-container-aboutus {
	height: 67vh;
	background-color: #c3e9ce;
}
