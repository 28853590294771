/**
 * 1. Vivek Yelethotadahalli Srinivas - 1002064152
 * 2. Pallavi Sunkara - 1002076310
 * 3. Sai Manideep Sanganabhatla - 1002118961
 * 4. Karthik Rama Raju Perearla - 1002086333
 * 5. Harsha Vardhan Nunna - 1002159569
 *
 * @format
 */

.home-wrapper {
	font-family: Arial, sans-serif;
	background-color: #c3e9ce;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	height: 78vh;
}

.qa-container {
	height: 40vh;
	width: 96%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	padding: 10px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	margin: 20px;
}

.qa-container h1 {
	font-size: 24px;
	color: #333;
}
