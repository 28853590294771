/**
 * 1. Vivek Yelethotadahalli Srinivas - 1002064152
 * 2. Pallavi Sunkara - 1002076310
 * 3. Sai Manideep Sanganabhatla - 1002118961
 * 4. Karthik Rama Raju Perearla - 1002086333
 * 5. Harsha Vardhan Nunna - 1002159569
 *
 * @format
 */

body {
	margin: 0;
}

.wrapper {
	font-family: Arial, sans-serif;
	background-color: #c3e9ce;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.signup-container {
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	padding: 20px;
	width: 300px;
	height: auto;
	text-align: center;
}

.signup-container h2 {
	font-size: 24px;
	margin-bottom: 20px;
}

.forgot-password-container {
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	padding: 20px;
	width: 370px;
	text-align: center;
}

.forgot-password-container h2 {
	font-size: 24px;
	margin-bottom: 10px;
}

.forgot-password-container p {
	font-size: 16px;
	margin-bottom: 20px;
}

.login-container {
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(0, 1, 1, 0.5);
	padding: 20px;
	width: 300px;
	height: 370px;
}

.login-container h2 {
	text-align: center;
}

.form-group {
	margin-bottom: 15px;
}

.form-group input {
	width: 100%;
	padding: 10px 3px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.btn-container {
	text-align: center;
}

.btn-signup {
	background-color: #28a745;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.btn-signup:hover {
	background-color: #1d822e;
}

.btn-container a {
	color: #ffffff;
}

.btn-cancel {
	background-color: #ccc;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	margin-right: 10px;
}

.btn-search {
	background-color: #007bff;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.btn-search:hover {
	background-color: #0056b3;
}

.btn {
	background-color: #007bff;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	margin-right: 10px;
}

.btn:hover {
	background-color: #0056b3;
}

.forgot-password {
	text-align: center;
	margin-top: 10px;
}

.forgot-password a {
	color: #007bff;
	text-decoration: none;
}

.create-account {
	text-align: center;
	margin-top: 10px;
}

.create-account .btn {
	background-color: #28a745;
}

.create-account a {
	color: #ffffff;
}

.create-account .btn:hover {
	background-color: #1d822e;
}

.error-message {
	color: red;
	font-size: 14px;
}

/* nav styles from styles.css */
/* .navBar {
	display: flex;
	align-items: center;
	justify-content: center;
	position: sticky;
	top: 0;
	cursor: pointer;
	background-color: #982652;
	background-size: cover;
}

.navList {
	width: 70%;
	display: flex;
}

.navList li {
	list-style: none;
	padding: 6px 40px;
}

.navList li a {
	text-decoration: none;
	color: black;
	font-weight: bold;
	padding: 2px 2px;
}

.navList li a:hover {
	color: grey;
} */

.loginButton .active-page {
	color: white; /* Underline the active page link */
	text-decoration: underline;
}

.logo img {
	width: 150px; /* Adjust the width as needed */
	height: auto; /* Maintain aspect ratio */
}
