/**
 * 1. Vivek Yelethotadahalli Srinivas - 1002064152
 * 2. Pallavi Sunkara - 1002076310
 * 3. Sai Manideep Sanganabhatla - 1002118961
 * 4. Karthik Rama Raju Perearla - 1002086333
 * 5. Harsha Vardhan Nunna - 1002159569
 *
 * @format
 */
/* 
body {
	font-family: Arial, sans-serif;
	background-color: #f0f0f0;
	margin: 0;
	padding: 0;
} */

.messaging-container {
	display: flex;
	max-width: 800px;
	margin: 20px auto;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	overflow: hidden;
}

.side-panel {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 10px;
	border-right: 1px solid #ccc;
	background-color: #f5f5f5;
	max-height: 600px;
	overflow-y: scroll;
}
.side-panel-btn {
	background-color: white;
	color: black;
}

.conversation {
	/* margin-bottom: 20px; */
	/* padding: 10px; */
	border: 1px solid #ccc;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.2s;
}

.conversation:hover {
	background-color: #e0e0e0;
}

.main-window {
	flex: 2 1;
	padding: 10px;
	background-color: #f9f9f9;
}

.header {
	background-color: black;
	color: #fff;
	padding: 10px;
}

.message-list {
	max-height: 400px;
	overflow-y: scroll;
	padding: 10px;
}

.message {
	margin-bottom: 10px;
	padding: 5px 10px;
	border-radius: 5px;
}

.from-friend {
	background-color: #f0f0f0;
	float: left;
}

.from-me {
	background-color: #73c3d5;
	color: #fff;
	float: right;
	text-align: right;
	align-self: flex-end;
}

.message-input {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	border-top: 1px solid #ccc;
}

textarea {
	flex-grow: 1;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-right: 10px;
}

button {
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 5px;
	padding: 8px 15px;
	cursor: pointer;
}
