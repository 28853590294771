/**
 * 1. Vivek Yelethotadahalli Srinivas - 1002064152
 * 2. Pallavi Sunkara - 1002076310
 * 3. Sai Manideep Sanganabhatla - 1002118961
 * 4. Karthik Rama Raju Perearla - 1002086333
 * 5. Harsha Vardhan Nunna - 1002159569
 *
 * @format
 */

.logoutButton {
	color: #c3e9ce;
}

.logoutButton a {
	text-decoration: none;
	color: #c3e9ce;
}

.logoutButton a:hover {
	color: grey;
}

.roleTitle {
	width: 70%;
	text-align: center;
}

.roleTitle h3 {
	text-decoration: none;
	color: black;
	font-weight: bold;
	padding: 2px 2px;
}

.active-page {
	color: white;
	text-decoration: underline;
}

.wrapper {
	font-family: Arial, sans-serif;
	background-color: #c3e9ce;
	margin: 0;
	padding: 0;
	display: flex;
	min-height: 70vh;
	justify-content: center;
	align-items: center;
	height: 80%;
}

.profile-container {
	max-width: 400px;
	background-color: #ffffff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	padding: 20px;
	border-radius: 5px;
	text-align: center;
}

.profile-link {
	text-decoration: none;
	background-color: #007bff;
	color: #fff;
	padding: 10px 20px;
	border-radius: 5px;
	display: inline-block;
	transition: background-color 0.3s ease;
}

.profile-link:hover {
	background-color: #0056b3;
}

/* .footer {
	background-color: #982652;
	color: #fff;
	padding: 20px;
	flex-grow: 1;
	text-align: center;
	border: 1px solid #000;
} */

.profile-form {
	max-width: 400px;
	background-color: #ffffff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	padding: 20px;
	border-radius: 5px;
}

.form-group {
	margin-bottom: 15px;
}

input[type="text"] {
	width: 100%;
	padding: 10px 3px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.update-button {
	text-align: center;
}

button {
	background-color: #007bff;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

button:hover {
	background-color: #0056b3;
}

.image-container {
	text-align: center;
	margin: 5px;
	width: 55%;
}

.image-container img {
	width: 55%;
	height: auto;
	display: block;
	margin: 0 auto;
}

.image-container + .image-container {
	margin-top: 20px;
}

/* officer and coordinator stuff below */

.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	margin: 20px;
	width: 20%;
}

.container-title {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 10px;
}

.button-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.main-button {
	padding: 10px 20px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.main-button a {
	text-decoration: none;
	color: white;
}

@media (max-width: 768px) {
	.container {
		flex-direction: column;
	}
}

.exam-container {
	max-width: 800px;
	margin: 20px auto;
	padding: 20px;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
