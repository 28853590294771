/**
 * 1. Vivek Yelethotadahalli Srinivas - 1002064152
 * 2. Pallavi Sunkara - 1002076310
 * 3. Sai Manideep Sanganabhatla - 1002118961
 * 4. Karthik Rama Raju Perearla - 1002086333
 * 5. Harsha Vardhan Nunna - 1002159569
 *
 * @format
 */

.logoutButton {
	color: #c3e9ce;
}

.logoutButton a {
	text-decoration: none;
	color: #c3e9ce;
}

.logoutButton a:hover {
	color: grey;
}

.roleTitle {
	width: 70%;
	text-align: center;
	color: white;
}

.roleTitle h3 {
	text-decoration: none;
	color: black;
	font-weight: bold;
	padding: 2px 2px;
}

.student-sectionContainer {
	height: 73vh;
	background-color: #c3e9ce;
}

.active-page {
	color: white;
	text-decoration: underline;
}

.student-wrapper {
	font-family: Arial, sans-serif;
	background-color: #c3e9ce;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 78vh;
}

.wrapper-top {
	font-family: Arial, sans-serif;
	background-color: #c3e9ce;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 48vh;
}

.wrapper-bottom {
	font-family: Arial, sans-serif;
	background-color: #c3e9ce;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	align-items: center;
	height: 30vh;
}

.profile-container {
	max-width: 400px;
	background-color: #ffffff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	padding: 20px;
	border-radius: 5px;
	text-align: center;
}

.profile-link {
	text-decoration: none;
	background-color: #007bff;
	color: #fff;
	padding: 10px 20px;
	border-radius: 5px;
	display: inline-block;
	transition: background-color 0.3s ease;
}

.profile-link:hover {
	background-color: #0056b3;
}

/* .footer {
	background-color: #982652;
	color: #fff;
	padding: 20px;
	flex-grow: 1;
	text-align: center;
	border: 1px solid #000;
} */

.profile-form {
	max-width: 400px;
	background-color: #ffffff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	padding: 20px;
	border-radius: 5px;
}

.form-group {
	margin-bottom: 15px;
}

input[type="text"],
textarea {
	width: 100%;
	padding: 10px 3px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.update-button {
	text-align: center;
}

button {
	background-color: #007bff;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

button:hover {
	background-color: #0056b3;
}

.course-container {
	width: 20%;
	background-color: #f0f0f0;
	border: 1px solid #ccc;
	margin: 10px;
	padding: 20px;
	display: inline-block;
	vertical-align: top;
	box-sizing: border-box;
}

.course-title {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 10px;
}

.course-details {
	margin-bottom: 10px;
}

.upcoming-events {
	margin-top: 20px;
}

.event-list {
	list-style-type: none;
	padding: 0;
}

.event-item {
	margin-bottom: 10px;
}

@media (max-width: 768px) {
	.course-container {
		width: 100%;
	}
}

.course-card-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.student-course-card {
	flex-basis: calc(50% - 10px); /* Two cards per row with a little spacing */
	background-color: #f9f9f9;
	border: 1px solid #ddd;
	border-radius: 5px;
	margin-bottom: 15px;
	padding: 15px;
	cursor: pointer;
	transition: background-color 0.3s;
	text-decoration: none;
	max-width: 50%;
}

.course-card:hover {
	background-color: #e0e0e0;
}

.course-title {
	font-size: 20px;
	margin: 0;
	color: black;
}

.course-details {
	font-size: 14px;
	color: black;
}

.student-container {
	max-width: 100%;
	padding: 20px;
	background-color: #c3e9ce;
	border-radius: 5px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.graded-item {
	margin-bottom: 20px;
	padding: 5px;
}
.graded-item:hover {
	background-color: lightgrey;
}

.exam-link {
	text-decoration: none;
	color: #007bff;
	font-weight: bold;
}

.upcoming-exam {
	background-color: #4caf50;
	color: #fff;
	padding: 10px;
	border-radius: 5px;
	text-align: center;
	font-weight: bold;
	margin-top: 20px;
}

.exam-container {
	max-width: 800px;
	margin: 20px auto;
	padding: 20px;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
